import styled from 'styled-components';
import { spacing } from '~/constants/spacing';
import { colors } from '~/constants/colors';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: ${spacing.big};

  @media (max-width: 750px) {
    > section {
      display: flex;
      flex-direction: column;
      padding-left: ${spacing.md} !important;
      padding-right: ${spacing.md} !important;
    }
  }

  @media (min-width: 750px) {
    > section:not(:last-of-type) {
      display: flex;
      width: 100%;

      @media (max-width: 750px) {
        flex-direction: column;
      }

      @media (min-width: 750px) {
        flex-direction: row;
        gap: ${spacing.xlg};

        > div:first-of-type {
          width: 50% !important;
          align-items: end;
        }

        > div:last-of-type {
          width: 50% !important;
          align-items: start;
        }
      }
    }
  }
`;

export const BehindTechnologySectionContainer = styled.section`
  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: ${spacing.sm};
    text-align: right;

    > div:last-of-type {
      margin-top: ${spacing.sm};
    }
  }

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    gap: ${spacing.lg};

    > div:first-of-type {
      display: flex;
      flex-direction: column;

      > div {
        text-align: left;
      }
    }

    > div:last-of-type {
      position: relative;
      min-height: 355px;

      img {
        position: absolute;
      }

      > img:first-of-type {
        right: 0;
        transform: translate(-25%, -50%);
      }

      > img:last-of-type {
        margin-top: 50px;
      }
    }
  }
`;

export const WhoWeAreSectionContainer = styled.section`
  @media (min-width: 750px) {
    position: relative;
    margin-top: ${spacing.xlg};

    div {
      padding-top: ${spacing.xlg};
      padding-bottom: ${spacing.xlg};
    }

    > div:first-of-type {
      border-right: 1px solid;
      border-image: linear-gradient(
        to bottom,
        ${colors.blue},
        ${colors.light_blue}
      );
      border-image-slice: 1;
    }

    > div:last-of-type {
      border-left: 1px solid;
      border-image: linear-gradient(
        to bottom,
        ${colors.blue},
        ${colors.light_blue}
      );
      border-image-slice: 1;
    }

    > div:first-of-type {
      img {
        z-index: 10;
      }
    }

    #horizontal-whoweare-hacking-line {
      position: absolute;
      height: 1px;
      width: 340px;
      top: 78%;
      left: 53%;
      z-index: 1;
      transform: translate(-50%, -50%);
      background-color: ${colors.text_white};
      opacity: 0.75;
    }
  }

  > div:last-of-type {
    p {
      margin-top: ${spacing.sm};
    }

    span {
      margin-left: 200px;
    }
  }

  @media (max-width: 750px) {
    div {
      width: 100% !important;
      padding: 0 !important;
    }

    > div:last-of-type {
      display: flex;
      flex-direction: column;
      span {
        width: 100%;
        margin-left: 0% !important;
        margin-top: ${spacing.md} !important;
        text-align: end;
      }
    }
  }
`;

export const CustomizedDeliveriesSectionContainer = styled.section`
  @media (min-width: 750px) {
    position: relative;
    div {
      padding-top: ${spacing.xlg};
      padding-bottom: ${spacing.xlg};
      display: flex;
      flex-direction: column;
    }

    > div:first-of-type {
      border-right: 1px solid ${colors.light_blue};
    }

    > div:last-of-type {
      border-left: 1px solid ${colors.light_blue};
    }

    > div:last-of-type {
      img {
        z-index: 10;
        transition: 0.25s;
        &:hover {
          scale: 1.05;
        }
      }
    }

    #horizontal-customizeddeliveries-hacking-line {
      position: absolute;
      height: 1px;
      width: 280px;
      top: 80%;
      left: 45%;
      z-index: 1;
      transform: translate(-50%, -50%);
      background-color: ${colors.text_white};
      opacity: 0.75;
    }
  }

  @media (max-width: 750px) {
    margin-top: ${spacing.xlg};
    width: 100% !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;

    div {
      padding: 0 !important;
    }

    > div:first-of-type {
      display: flex;
      flex-direction: column;
      margin-bottom: ${spacing.lg};

      h3,
      p {
        text-align: left !important;
        width: 100% !important;
      }
    }
  }

  > div:first-of-type {
    p {
      margin-top: ${spacing.sm};
      text-align: right;
    }

    span {
      margin-right: 200px;
      margin-top: ${spacing.md};
    }

    h3 {
      text-align: right;
    }
  }
`;

export const DigitalAssementSectionContainer = styled.section`
  @media (min-width: 750px) {
    position: relative;

    > div:first-of-type {
      z-index: 10;
    }

    div {
      padding-top: ${spacing.xlg};
      padding-bottom: ${spacing.xlg};
      display: flex;
      flex-direction: column;
    }

    > div:first-of-type {
      border-right: 1px solid;
      border-image: linear-gradient(
        to bottom,
        ${colors.light_blue},
        ${colors.light_green}
      );
      border-image-slice: 1;
    }

    > div:last-of-type {
      border-left: 1px solid;
      border-image: linear-gradient(
        to bottom,
        ${colors.light_blue},
        ${colors.light_green}
      );
      border-image-slice: 1;
    }

    #horizontal-digitalassement-hacking-line {
      position: absolute;
      height: 1px;
      width: 340px;
      top: 57%;
      left: 53%;
      z-index: 1;
      transform: translate(-50%, -50%);
      background-color: ${colors.text_white};
      opacity: 0.75;
    }

    > div:last-of-type {
      span {
        margin-left: 200px;
        margin-top: ${spacing.md};
      }
    }
  }

  > div:last-of-type {
    b {
      color: ${colors.blue} !important;
    }

    p {
      text-align: left;
    }
  }

  img {
    transition: 0.25s;
    &:hover {
      scale: 1.05;
    }
  }

  > div:last-of-type {
    p {
      margin-top: ${spacing.sm};
    }
  }

  @media (max-width: 750px) {
    margin-top: ${spacing.big};
    display: flex;
    flex-direction: column;

    div {
      width: 100% !important;
      padding: 0 !important;
    }

    img {
      max-width: 100%;
    }

    > div:last-of-type {
      display: flex;
      flex-direction: column;
      > span {
        text-align: right !important;
        align-self: end;
      }
    }
  }
`;

export const DigitalBluePrintSectionContainer = styled.section`
  @media (min-width: 750px) {
    position: relative;
    div {
      padding-top: ${spacing.xlg};
      padding-bottom: ${spacing.xlg};
      display: flex;
      flex-direction: column;
    }

    > div:first-of-type {
      border-right: 1px solid;
      border-image: linear-gradient(
        to bottom,
        ${colors.light_green},
        ${colors.green}
      );
      border-image-slice: 1;
    }

    > div:last-of-type {
      border-left: 1px solid;
      border-image: linear-gradient(
        to bottom,
        ${colors.light_green},
        ${colors.green}
      );
      border-image-slice: 1;
    }

    > div:last-of-type {
      img {
        z-index: 10;
      }
    }

    #horizontal-digitalblueprint-hacking-line {
      position: absolute;
      height: 1px;
      width: 340px;
      top: 65%;
      left: 47%;
      z-index: 1;
      transform: translate(-50%, -50%);
      background-color: ${colors.text_white};
      opacity: 0.75;
    }

    > div:first-of-type {
      p {
        text-align: right;
      }

      span {
        margin-top: ${spacing.md} !important;
        margin-right: 200px;
      }
    }
  }

  > div:first-of-type {
    b {
      color: ${colors.purple} !important;
    }

    p {
      margin-top: ${spacing.sm};
    }

    h3 {
      text-align: right;
    }
  }

  @media (max-width: 750px) {
    margin-top: ${spacing.big};
    display: flex;
    flex-direction: column-reverse !important;

    div {
      width: 100% !important;
      padding: 0 !important;
    }

    img {
      max-width: 100%;
    }

    > div:first-of-type {
      margin-top: ${spacing.sm};
      display: flex;
      flex-direction: column;
      text-align: left !important;

      h3,
      p {
        text-align: left;
        width: 100%;
      }
    }
  }

  img {
    transition: 0.25s;
    &:hover {
      scale: 1.05;
    }
  }
`;

export const PerformanceRackingSectionContainer = styled.section`
  @media (min-width: 750px) {
    position: relative;
    div {
      padding-top: ${spacing.xlg};
      padding-bottom: ${spacing.xlg};
      display: flex;
      flex-direction: column;
    }

    > div:first-of-type {
      border-right: 1px solid ${colors.green};
    }

    > div:last-of-type {
      border-left: 1px solid ${colors.green};
    }

    #horizontal-performance-hacking-line {
      position: absolute;
      height: 1px;
      width: 340px;
      top: 62%;
      left: 53%;
      z-index: 1;
      transform: translate(-50%, -50%);
      background-color: ${colors.text_white};
      opacity: 0.75;
    }

    #end-circle {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${colors.green};
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    > div:first-of-type {
      img {
        z-index: 10;
      }
    }

    > div:last-of-type {
      p {
        text-align: left;
      }

      span {
        margin-left: 200px;
        margin-top: ${spacing.md};
      }
    }
  }

  @media (max-width: 750px) {
    margin-top: ${spacing.lg};
    display: flex;
    flex-direction: column;

    div {
      width: 100% !important;
      padding: 0 !important;
    }

    img {
      max-width: 100%;
    }

    > div:last-of-type {
      margin-top: ${spacing.sm};
      display: flex;
      flex-direction: column;
    }
  }

  > div:last-of-type {
    b {
      color: ${colors.green} !important;
    }

    p {
      margin-top: ${spacing.sm};
    }
  }

  img {
    transition: 0.25s;
    &:hover {
      scale: 1.05;
    }
  }
`;

export const LeapToTheFutureSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.big};

  @media (max-width: 750px) {
    > img {
      max-width: 100% !important;
      height: 119px !important;
    }
  }

  img {
    transition: 0.25s;
    &:hover {
      scale: 0.95;
    }
  }

  hr {
    margin-top: ${spacing.lg};
  }
`;

export const SuccessCasesSectionContainer = styled.section`
  @media (min-width: 750px) {
    display: flex;
    flex-direction: column !important;
    gap: 0 !important;
    justify-content: center;
    align-items: center;
    margin-top: ${spacing.lg};
    text-align: center;

    span {
      margin-top: ${spacing.sm};
    }
  }

  p {
    margin-top: ${spacing.sm};
  }

  @media (max-width: 750px) {
    margin-top: ${spacing.lg};
    display: flex;
    flex-direction: column;

    span {
      margin-top: ${spacing.md};
      align-self: end;
    }
  }
`;

export const SpecializedPartnershipsSectionContainer = styled.section`
  margin-top: ${spacing.big};
  display: flex;
  gap: 0 !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;

  > span {
    margin-top: ${spacing.md};
  }

  @media (min-width: 750px) {
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: ${spacing.lg};
      gap: ${spacing.sm};

      > span {
        padding: ${spacing.sm};
        width: 150px;
        display: flex;
        justify-content: center;
        border: 1px solid ${colors.text_white};
      }
    }
  }

  @media (max-width: 750px) {
    width: 100% !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;

    > div {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: ${spacing.sm};
      margin-top: ${spacing.lg};
      > span {
        padding: ${spacing.sm};
        width: 150px;
        display: flex;
        justify-content: center;
        border: 1px solid ${colors.text_white};
      }
    }
  }
`;

export const TalkWithUsSectionContainer = styled.section`
  width: 100% !important;
  margin-top: ${spacing.big};
  display: flex;
  justify-content: center;
  gap: 0;

  @media (min-width: 750px) {
    > div {
      width: 50%;
    }
  }

  @media (max-width: 750px) {
    text-align: center;
  }
`;

export const HomeRetanguleImagesWrapper = styled.div`
  @media (min-width: 750px) {
    position: relative;
    img {
      position: absolute;
    }
  }

  img:first-of-type {
    z-index: 2;
    left: 200px;
  }

  img:nth-of-type(2) {
    z-index: 1;
  }

  img:last-of-type {
    z-index: 3;
    transform: translate(-5%, -15%);

    @media (max-width: 750px) {
      width: 100%;
      height: 400px;
    }
  }
`;

interface IMobileSpanWithLineContainerProps {
  readonly lineWidth: string;
}
export const MobileSpanWithLineContainer = styled.span<IMobileSpanWithLineContainerProps>`
  margin-top: ${spacing.md};
  position: relative;
  width: 100%;

  #line {
    position: absolute;
    height: 1px;
    width: ${({ lineWidth }) => lineWidth} !important;
    background-color: ${colors.text_white};
    transform: translate(-0%);
    margin-top: 10px;
  }
`;
