import { FC } from 'react';

import { BiLogoInstagramAlt, BiLogoLinkedinSquare } from 'react-icons/bi';

import Image from 'next/image';

import { colors } from '~/constants/colors';

import { FooterContainer } from './styles';
import { TypographSpan } from '../typograph';
import Logo from '../../../public/logo_footer.png';

export const Footer: FC = () => {
  return (
    <FooterContainer>
      <div>
        <Image src={Logo} alt="logo" />
      </div>

      <div>
        <div>
          <BiLogoInstagramAlt size={26} />
          <BiLogoLinkedinSquare size={26} />
        </div>

        <TypographSpan
          uppercase
          size={10}
          lineHeight={14}
          letterSpacing={3}
          weak
          color={colors.black}
        >
          copyright© leap 2024. Todos os direitos reservados
        </TypographSpan>
      </div>
    </FooterContainer>
  );
};
