import { FC, useCallback, useEffect, useId, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import Image, { StaticImageData } from 'next/image';
import { NextPage } from 'next';

import { IFCWithChildren } from '~/interfaces/i-fc-with-children';
import { useLayoutType } from '~/hooks/use-layout-type';
import { RoutesEnum } from '~/enums/routes';
import { spacing } from '~/constants/spacing';
import { colors } from '~/constants/colors';
import {
  TypographH1,
  TypographH2,
  TypographH3,
  TypographP,
  TypographSpan,
} from '~/components/typograph';
import { TalkWithUs } from '~/components/talkwithus';
import { Footer } from '~/components/footer';

import {
  BehindTechnologySectionContainer,
  CustomizedDeliveriesSectionContainer,
  DigitalAssementSectionContainer,
  DigitalBluePrintSectionContainer,
  HomeContainer,
  HomeRetanguleImagesWrapper,
  LeapToTheFutureSectionContainer,
  MobileSpanWithLineContainer,
  PerformanceRackingSectionContainer,
  SpecializedPartnershipsSectionContainer,
  SuccessCasesSectionContainer,
  TalkWithUsSectionContainer,
  WhoWeAreSectionContainer,
} from '../../pages-style/home/styles';
import PerformanceRacking from '../../../public/performance_racking.png';
import LeapToTheFuture from '../../../public/logo_leap_to_the_future.png';
import HomeLogoDetails from '../../../public/home-logo-details.png';
import HomeRetangule2 from '../../../public/home_retangule2.png';
import HomeRetangule1 from '../../../public/home_retangule1.png';
import DigitalBluePrint from '../../../public/digital_blueprint.png';
import DigitalAssement from '../../../public/digital_assement.png';
import CustomizedDeliveries from '../../../public/customized_deliveries.png';

/**
 * @todo (geral)
 * 1. incluir fonte "Hanken Grotesk" [OK]
 * 2. salvar "rota" atual e anterior
 * 3. restante das telas
 *
 * @todo (home)
 * 1. efeito de background (incluir uma imagem única em looping)
 * 2. efeito no scroll das 2 primeiras imagens (starbucks) [OK]
 * 3. conector vertical com cor gradiente [OK]
 * 4. conectores horizontais das seções (no span) [OK]
 * 5. componente de contato genérico [OK]
 * 6. responsividade para mobile [OK]
 * 7. aprimorar espaçamentos conforme figma [OK]
 */

interface ImageWithScrollEffectProps {
  readonly src: StaticImageData;
  readonly alt: string;
  readonly initialBottom?: number;
  readonly initialTop?: number;
  readonly maxTop?: number;
  readonly maxBottom?: number;
  readonly onScrollTopAmount?: number;
  readonly onScrollBottomAmount?: number;
}

const ImageWithScrollEffect: FC<ImageWithScrollEffectProps> = ({
  src,
  alt,
  initialBottom,
  initialTop,
  maxBottom,
  maxTop,
  onScrollBottomAmount,
  onScrollTopAmount,
}) => {
  const id = useId();
  const [top, setTop] = useState<number>(initialTop);
  const [bottom, setBottom] = useState<number>(initialBottom);
  const lastScrollTopRef = useRef<number>(0);

  const handleScrollEffect = useCallback(
    (event: Event) => {
      const currentScrollTop = (event.target as any).scrollTop;

      if (onScrollBottomAmount) {
        if (currentScrollTop > lastScrollTopRef.current) {
          setBottom((prev) =>
            prev + onScrollBottomAmount > maxBottom
              ? maxBottom
              : prev + onScrollBottomAmount
          );
        }

        if (currentScrollTop < lastScrollTopRef.current) {
          setBottom((prev) => prev - onScrollBottomAmount);
        }
      } else if (onScrollTopAmount) {
        if (currentScrollTop > lastScrollTopRef.current) {
          setTop((prev) => prev + onScrollTopAmount);
        }

        if (currentScrollTop < lastScrollTopRef.current) {
          setTop((prev) =>
            Math.abs(prev - onScrollTopAmount) > maxTop
              ? maxTop
              : prev - onScrollTopAmount
          );
        }
      }

      lastScrollTopRef.current = currentScrollTop <= 0 ? 0 : currentScrollTop;
    },
    [maxBottom, maxTop, onScrollBottomAmount, onScrollTopAmount]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEffect, {
      capture: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScrollEffect, {
        capture: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Image
      id={id}
      src={src}
      alt={alt}
      style={{
        bottom: `${bottom}px`,
        top: `${top}px`,
        transition: '0.25s',
      }}
    />
  );
};

interface IMobileSpanWithLineProps {
  position: 'left' | 'right';
  lineWidth: number;
}
const MobileSpanWithLine: IFCWithChildren<IMobileSpanWithLineProps> = ({
  children,
  lineWidth,
  position,
}) => {
  return (
    <MobileSpanWithLineContainer
      lineWidth={`${lineWidth}%`}
      style={{
        textAlign: position,
      }}
    >
      <div id="line" />
      <TypographSpan
        uppercase
        underline
        bold
        size={12}
        lineHeight={13.8}
        letterSpacing={5}
        color={colors.text_white}
      >
        {children}
      </TypographSpan>
    </MobileSpanWithLineContainer>
  );
};

const Home: NextPage = () => {
  const isMobile = useLayoutType('mobile');
  const router = useRouter();

  const BehindTechnologySection = (
    <BehindTechnologySectionContainer>
      <div>
        <div>
          <TypographH1 size={64} color={colors.blue} bold>
            Behind the tecnology
          </TypographH1>
        </div>

        <div>
          <TypographH2 size={isMobile ? 38 : 44} color={colors.text_white} bold>
            THE BEST STRATEGY
          </TypographH2>
        </div>

        <div>
          <TypographSpan
            color="#E0E0E0"
            size={14}
            letterSpacing={1}
            lineHeight={17.5}
            uppercase
            weak
          >
            por trás da tecnologia, a melhor estratégia
          </TypographSpan>
        </div>

        <div>
          <TypographH3
            color={colors.text_white}
            size={isMobile ? 14 : 16}
            lineHeight={20.85}
            bold
            letterSpacing={10}
            uppercase
          >
            leap to the future
          </TypographH3>
          <TypographSpan color="#E0E0E0" size={isMobile ? 14 : 16} weak>
            {isMobile ? (
              <>
                Trazendo a melhor solução para
                <br />o seu negócio
              </>
            ) : (
              <>Trazendo a melhor solução para o seu negócio</>
            )}
          </TypographSpan>
        </div>
      </div>

      <HomeRetanguleImagesWrapper>
        <ImageWithScrollEffect
          src={HomeRetangule2}
          alt="ret2"
          initialBottom={0}
          maxBottom={30}
          onScrollBottomAmount={0.5}
        />
        <ImageWithScrollEffect
          src={HomeRetangule1}
          alt="ret1"
          initialTop={15}
          maxTop={-30}
          onScrollTopAmount={0.5}
        />
        <Image src={HomeLogoDetails} alt="details" width={600} />
      </HomeRetanguleImagesWrapper>
    </BehindTechnologySectionContainer>
  );

  const WhoWeAreSection = (
    <WhoWeAreSectionContainer style={{ gap: 0 }}>
      <div style={{ paddingRight: spacing.xlg, alignItems: 'end' }}>
        <></>
      </div>

      <div style={{ paddingLeft: spacing.xlg, alignItems: 'start' }}>
        <TypographH3
          uppercase
          bold
          color={colors.text_white}
          size={18}
          letterSpacing={10}
          lineHeight={23.4}
        >
          quem somos
        </TypographH3>

        <TypographP size={14} lineHeight={19.6} color="#E0E0E0" weak>
          Nos últimos <b style={{ color: colors.text_white }}>20 anos</b>{' '}
          estivemos focados em
          <br />
          <b style={{ color: colors.text_white }}>
            estruturar e transformar negócios digitais.
          </b>
          <br />
          Em 2023, a Leap nasce com a missão de unir <br /> experiência de
          mercado, um profundo
          <br /> conhecimento do varejo e um mindset de
          <br /> consultoria que de maneira analítica.
        </TypographP>

        <br />

        <TypographSpan
          pointer
          uppercase
          underline
          bold
          size={12}
          lineHeight={13.8}
          letterSpacing={5}
          color={colors.text_white}
          onClick={() => router.push(RoutesEnum.about)}
        >
          conheça a jornada
        </TypographSpan>
      </div>

      <span id="horizontal-whoweare-hacking-line" />
    </WhoWeAreSectionContainer>
  );

  const CustomizedDeliveriesSection = (
    <CustomizedDeliveriesSectionContainer style={{ gap: 0 }}>
      <div style={{ paddingRight: spacing.xlg, alignItems: 'end' }}>
        <TypographH3
          uppercase
          bold
          color={colors.text_white}
          size={18}
          letterSpacing={10}
          lineHeight={23.4}
        >
          entregas
          <br /> customizadas
        </TypographH3>

        <TypographP size={14} lineHeight={19.6} color="#E0E0E0" weak>
          Temos como tese a sede de impulsionar
          <br /> o desenvolvimento do seu negócio,
          <br /> contando com o suporte dos melhores
          <br /> parceiros no ecossistema digital.
        </TypographP>

        <TypographP size={14} lineHeight={19.6} color="#E0E0E0" weak>
          Nos relacionamos de maneira{' '}
          <b style={{ color: colors.text_white }}>agnóstica</b>
          <br />
          <b style={{ color: colors.text_white }}>ao ecossistema</b> a fim de
          conectar
          <br />
          empresas, com as melhores soluções
          <br />
          para o seu negócio,{' '}
          <b style={{ color: colors.text_white }}>potencializando o</b>
          <br />
          <b style={{ color: colors.text_white }}>uso da tecnologia</b>{' '}
          implementada em
          <br />
          favor dos seus objetivos estratégicos
        </TypographP>

        {!isMobile && (
          <TypographSpan
            uppercase
            underline
            bold
            size={12}
            lineHeight={13.8}
            letterSpacing={5}
            color={colors.text_white}
          >
            entenda nossas soluções
          </TypographSpan>
        )}
      </div>

      <div style={{ paddingLeft: spacing.xlg, alignItems: 'start' }}>
        <Image
          src={CustomizedDeliveries}
          alt="entregas customizadas"
          height={324}
          width={406}
        />
      </div>

      <span id="horizontal-customizeddeliveries-hacking-line" />
      {isMobile && (
        <MobileSpanWithLine position="right" lineWidth={20}>
          entenda nossas soluções
        </MobileSpanWithLine>
      )}
    </CustomizedDeliveriesSectionContainer>
  );

  const DigitalAssementSection = (
    <DigitalAssementSectionContainer style={{ gap: 0 }}>
      <div style={{ paddingRight: spacing.xlg, alignItems: 'end' }}>
        <Image src={DigitalAssement} alt="digital assement" />
      </div>

      <div style={{ paddingLeft: spacing.xlg, alignItems: 'start' }}>
        <TypographH3
          uppercase
          bold
          color={colors.text_white}
          size={18}
          letterSpacing={10}
          lineHeight={23.4}
        >
          <b color={colors.blue}>1</b>digital
          <br />
          assement
        </TypographH3>

        <TypographP size={14} lineHeight={19.6} color="#E0E0E0" weak>
          Para empresas que estão começando ou ainda
          <br />
          no papel. Preparação de jornada: Arquitetura
          <br />e estruturação de negócio. Análise de
          <br />
          necessidades e business plan.
        </TypographP>

        <br />

        {isMobile ? (
          <MobileSpanWithLine position="right" lineWidth={60}>
            saiba mais
          </MobileSpanWithLine>
        ) : (
          <TypographSpan
            uppercase
            underline
            bold
            size={12}
            lineHeight={13.8}
            letterSpacing={5}
            color={colors.text_white}
          >
            saiba mais
          </TypographSpan>
        )}
      </div>

      <span id="horizontal-digitalassement-hacking-line" />
    </DigitalAssementSectionContainer>
  );

  const DigitaBluePrintSection = (
    <DigitalBluePrintSectionContainer style={{ gap: 0 }}>
      <div style={{ paddingRight: spacing.xlg, alignItems: 'end' }}>
        <TypographH3
          uppercase
          bold
          color={colors.text_white}
          size={18}
          letterSpacing={10}
          lineHeight={23.4}
        >
          <b color={colors.purple}>2</b>digital
          <br />
          blueprint
        </TypographH3>

        <TypographP size={14} lineHeight={19.6} color="#E0E0E0" weak>
          Para empresas que estão começando ou ainda
          <br />
          no papel. Materialização e Resolução de
          <br />
          problemas. Hub com parceiros, trazendo os
          <br />
          melhores especialistas na plataformas para
          <br />
          fazer as entregas
        </TypographP>

        {isMobile ? (
          <MobileSpanWithLine position="right" lineWidth={60}>
            saiba mais
          </MobileSpanWithLine>
        ) : (
          <TypographSpan
            uppercase
            underline
            bold
            size={12}
            lineHeight={13.8}
            letterSpacing={5}
            color={colors.text_white}
          >
            saiba mais
          </TypographSpan>
        )}
      </div>

      <div style={{ paddingLeft: spacing.xlg, alignItems: 'start' }}>
        <Image src={DigitalBluePrint} alt="digital blue print" />
      </div>

      <span id="horizontal-digitalblueprint-hacking-line" />
    </DigitalBluePrintSectionContainer>
  );

  const PerformanceRackingSection = (
    <PerformanceRackingSectionContainer style={{ gap: 0 }}>
      <div style={{ paddingRight: spacing.xlg, alignItems: 'end' }}>
        <Image src={PerformanceRacking} alt="performance racking" />
      </div>

      <div style={{ paddingLeft: spacing.xlg, alignItems: 'start' }}>
        <TypographH3
          uppercase
          bold
          color={colors.text_white}
          size={18}
          letterSpacing={10}
          lineHeight={23.4}
        >
          <b color={colors.green}>3</b>performance
          <br />
          racking
        </TypographH3>

        <TypographP size={14} lineHeight={19.6} color="#E0E0E0" weak>
          Impulsione o seu negócio. Maximização
          <br />
          acelerada da problemas eficiência e<br />
          desempenho Gerenciar e geara as instalações,
          <br />
          oferecer treinamento e orientação
        </TypographP>

        <br />

        {isMobile ? (
          <MobileSpanWithLine position="right" lineWidth={60}>
            saiba mais
          </MobileSpanWithLine>
        ) : (
          <TypographSpan
            uppercase
            underline
            bold
            size={12}
            lineHeight={13.8}
            letterSpacing={5}
            color={colors.text_white}
          >
            saiba mais
          </TypographSpan>
        )}
      </div>

      <span id="horizontal-performance-hacking-line" />
      <span id="end-circle" />
    </PerformanceRackingSectionContainer>
  );

  const SuccessCasesSection = (
    <SuccessCasesSectionContainer>
      <TypographH3
        uppercase
        bold
        color={colors.green}
        size={18}
        letterSpacing={10}
        lineHeight={23.4}
      >
        cases de sucesso
      </TypographH3>

      <TypographP size={14} lineHeight={19.6} color="#E0E0E0" weak>
        Confira algumas experiência reais que nossos clientes tiveram com a Leap
      </TypographP>

      <TypographSpan
        uppercase
        underline
        bold
        size={isMobile ? 10 : 12}
        lineHeight={13.8}
        letterSpacing={5}
        color={colors.text_white}
      >
        inspire-se com nossas histórias
      </TypographSpan>
    </SuccessCasesSectionContainer>
  );

  const LeapToTheFutureSection = (
    <LeapToTheFutureSectionContainer>
      <Image
        src={LeapToTheFuture}
        alt="leap to the future"
        {...(!isMobile && {
          width: 629,
        })}
      />

      {isMobile && <hr />}
    </LeapToTheFutureSectionContainer>
  );

  const SpecializedPartnershipsSection = (
    <SpecializedPartnershipsSectionContainer>
      <TypographH2
        bold
        color={colors.green}
        uppercase
        letterSpacing={6}
        lineHeight={28.8}
        size={24}
      >
        parcerias especializadas
      </TypographH2>

      {isMobile ? (
        <TypographSpan
          weak
          size={14}
          lineHeight={22.4}
          color={colors.text_white}
          letterSpacing={4}
          uppercase
        >
          Nos relacionamos de maneira agnóstica ao ecossistema a fim de conectar
          empresas, com as melhores soluções para o seu negócio
        </TypographSpan>
      ) : (
        <TypographSpan
          weak
          size={14}
          lineHeight={22.4}
          color={colors.text_white}
          letterSpacing={4}
          uppercase
        >
          Nos relacionamos de maneira agnóstica ao ecossistema a fim de
          <br />
          conectar empresas, com as melhores soluções para o seu negócio
        </TypographSpan>
      )}

      <div>
        <TypographSpan
          uppercase
          size={14}
          color={colors.purple}
          lineHeight={19.6}
          weak
        >
          vtex
        </TypographSpan>
        <TypographSpan
          uppercase
          size={14}
          color={colors.purple}
          lineHeight={19.6}
          weak
        >
          totvs
        </TypographSpan>
        <TypographSpan
          uppercase
          size={14}
          color={colors.purple}
          lineHeight={19.6}
          weak
        >
          insider
        </TypographSpan>
        <TypographSpan
          uppercase
          size={14}
          color={colors.purple}
          lineHeight={19.6}
          weak
        >
          amazon
        </TypographSpan>
      </div>
    </SpecializedPartnershipsSectionContainer>
  );

  const TalkWithUsSectin = (
    <TalkWithUsSectionContainer>
      <TalkWithUs />
    </TalkWithUsSectionContainer>
  );

  return (
    <HomeContainer id="home-container">
      {BehindTechnologySection}
      {WhoWeAreSection}
      {CustomizedDeliveriesSection}
      {DigitalAssementSection}
      {DigitaBluePrintSection}
      {PerformanceRackingSection}
      {SuccessCasesSection}
      {LeapToTheFutureSection}
      {SpecializedPartnershipsSection}
      {TalkWithUsSectin}

      <Footer />
    </HomeContainer>
  );
};

export default Home;
