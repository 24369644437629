import styled from 'styled-components';
import { spacing } from '~/constants/spacing';
import { colors } from '~/constants/colors';

export const FooterContainer = styled.footer`
  margin-top: ${spacing.xlg};
  display: flex;
  background-color: ${colors.green};
  width: 100%;

  @media (max-width: 750px) {
    padding: ${spacing.md};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${spacing.sm};

    > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: ${spacing.sm};
    }
  }

  @media (min-width: 750px) {
    height: 128px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: ${spacing.xlg};
    padding-right: ${spacing.xlg};

    > div:last-of-type {
      display: flex;
      flex-direction: column;
      gap: ${spacing.md};

      > div {
        display: flex;
        justify-content: end;
        gap: ${spacing.sm};
      }
    }
  }
`;
