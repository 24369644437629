import styled from 'styled-components';
import { spacing } from '~/constants/spacing';
import { colors } from '~/constants/colors';

export const TalkWithUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    margin-top: ${spacing.md};
  }

  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${spacing.sm};
    margin-top: ${spacing.md};

    > div {
      display: flex;
      @media (min-width: 750px) {
        flex-direction: row;
      }

      @media (max-width: 750px) {
        flex-direction: column;
      }

      gap: ${spacing.sm};
    }

    input,
    textarea {
      width: 100%;
      height: 48px;
      padding-left: 10px;
      padding-right: 10px;
      color: ${colors.blue};
      text-transform: uppercase;
    }

    textarea {
      padding-top: ${spacing.sm};
      height: 125px;
      font-size: 12px;
    }

    button {
      background-color: ${colors.green};
      height: 48px;
      margin-top: ${spacing.sm};
      align-self: end;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 12px;

      @media (min-width: 750px) {
        width: 416px;
      }

      @media (max-width: 750px) {
        width: 100%;
      }
    }
  }
`;
