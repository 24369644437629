import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useCallback,
  useState,
} from 'react';

import { colors } from '~/constants/colors';

import { TalkWithUsContainer } from './styles';
import { ITalkWithUs } from './interfaces/takwithus-request';
import { TypographH2, TypographSpan } from '../typograph';

export const TalkWithUs: FC = () => {
  const [data, setData] = useState<ITalkWithUs>({} as ITalkWithUs);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setData((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    },
    []
  );

  const handleSubmit: FormEventHandler = useCallback((event) => {
    event.preventDefault();
    /**
     * @todo
     * - disparar requisição
     */
  }, []);

  return (
    <TalkWithUsContainer>
      <TypographH2
        bold
        color={colors.green}
        uppercase
        letterSpacing={6}
        lineHeight={28.8}
        size={24}
      >
        fale conosco !
      </TypographH2>

      <TypographSpan
        weak
        size={14}
        lineHeight={22.4}
        color={colors.text_white}
        letterSpacing={4}
        uppercase
      >
        estamos aqui para te ajudar a impulsionar seu negócio e trazer
        resultados
      </TypographSpan>

      <form onSubmit={handleSubmit}>
        <div>
          <input
            name="name"
            placeholder="nome"
            value={data.name}
            onChange={handleChange}
            required
          />
          <input
            name="surname"
            placeholder="sobrenome"
            value={data.surname}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <input
            name="email"
            placeholder="e-mail"
            value={data.email}
            onChange={handleChange}
            required
          />
          <input
            name="company"
            placeholder="empresa"
            value={data.company}
            onChange={handleChange}
            required
          />
        </div>

        <textarea placeholder="mensagem" />

        <button type="submit">enviar</button>
      </form>
    </TalkWithUsContainer>
  );
};
